<script lang="ts">
export interface Props {
  width: number
  height: number
}
</script>

<script lang="ts" setup>
const props = defineProps<Props>()

const attributes = computed(() => ({
  style: {
    width: `${props.width * 8}px`,
    height: `${props.height * 8}px`,
  },
  class: 'absolute max-w-none origin-top-left scale-[.125]',
}))
</script>

<template>
  <span
    class="relative inline-block"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <slot :attributes="attributes" />
  </span>
</template>
